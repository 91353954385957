<template>
  <div class="content" style="height: 100%">
    <pulse-loader style="position: relative; top: 50%; left: 50%;" color="#6177FF" />
  </div>
</template>

<script>
export default {
  name: "Home",
  mounted() {
    window.location.href = 'https://' + process.env.VUE_APP_LK_API + '/ru/login'
  }
}
</script>

<style scoped>

</style>