<template>
  <div class="content">
    <Breadcrumbs title="Календарь обучения" />

    <div class="history-back"><a href="https://lk.exportcenter.ru/ru/educational-services">Вернуться в каталог</a></div>

    <div class="content_40 content_300 mb-5">Календарь обучения</div>

    <div class="seminars-filters">
      <div class="seminars-data__filters">
        <div class="seminars-datepicker mb-4">
          <div></div>
        </div>
<!--        <div class="form-chechboxes" v-if="courses && showFilters">
          <template v-for="(course, key) in courses">
            <div class="form-checkbox" v-if="course.events_count" :key="key">
              <div class="form-checkbox__wrapper">
                <input type="checkbox" v-model="filterCourses[course.id]" />
                <label>{{ course.seminar_name }} <span class="count">({{ course.events_count }})</span></label>
              </div>
            </div>
          </template>
        </div>-->
      </div>
      <template v-if="seminars">
        <div class="seminars-data__content" v-if="seminars.length">
          <carousel :items="1" :nav="false" class="carousel-courses carousel-calendar">
            <div class="item" v-for="(seminars, index) in chunkSeminars" :key="index">
              <div class="seminar-wrapper" v-for="(seminar, key) in seminars" :key="key">
                <div class="seminar-teaser">
                  <div class="seminar-teaser__author">
                    <div class="seminar-teaser__info">
                      <div class="seminar-teaser__lead">
                        <span class="icon"></span>
                        <template v-if="seminar.tutors.length">
                          {{ seminar.tutors[0].fullname }}
                        </template>
                      </div>
                    </div>
                  </div>
                  <div class="seminar-teaser__image" :style="{backgroundImage: seminar.cover ? 'url(https:' + seminar.cover['900x'] + ')' : 'url(../img/calendar-item-bg.png)'}"></div>
                  <div class="seminar-teaser__header">
                    <div class="seminar-teaser__date">
                      {{seminar.date.start | formatDate('DD')}} {{seminar.date.start | formatDate('M')}} <div class="seminar-teaser__time">{{ seminar.date.start | formatDate('YYYY | HH:mm') }}</div>
                    </div>
                    <div class="seminar-teaser__title">{{ seminar.title }}</div>
                  </div>
                  <div class="seminar-teaser__location">{{ seminar.location }}</div>
                  <button @click="enroll(seminar.id)" :disabled="loading" class="btn btn-primary">Записаться</button>
                </div>
              </div>
            </div>
          </carousel>
        </div>
        <div class="seminars-data__content" v-else>
          <div class="no-seminar">
            В настоящее время экспортные семинары в вашем регионе не запланированы
          </div>
        </div>
      </template>
      <pulse-loader v-else style="position: relative; top: 50%; left: 50%;" color="#6177FF" />
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "../components/Breadcrumbs";
import carousel from 'vue-owl-carousel'
import ApiService from "../services/api.service";
import $ from 'jquery';
import {TokenService} from "../services/token.service";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
window.jQuery = $
import _ from 'lodash';
import moment from "moment/moment";
import {eventBus} from "../main";
export default {
  name: "Calendar",
  components: {Breadcrumbs, carousel, PulseLoader},
  data() {
    return {
      courses: null,
      seminars: null,
      loading: false,
      showFilters: false,
      filterCourses: {}
    }
  },
  mounted() {
    eventBus.$on('auth', () => {
      ApiService.get('ow/events', {
        withCredentials: false,
        params: {
          'filters[type]': 'future',
          'filters[course]': this.$route.query.course,
          'filters[region]': this.$route.query.region,
          'filters[order_id]': this.$route.query.order_id,
          user: TokenService.getExportUserId(),
        }
      }).then(res => {
        this.seminars = res.data.data
        require('bootstrap-datepicker');
        require('bootstrap-datepicker/js/locales/bootstrap-datepicker.ru');
        let seminarDates = []
        this.seminars.forEach(seminar => {
          seminarDates.push((new Date(moment(seminar.date.start).format('M/D/Y'))).getTime())
        })
        $('.seminars-datepicker > div').datepicker({
          todayHighlight: true,
          language: 'ru',
          isRTL: false,
          beforeShowDay: function (date) {
            return {
              enabled: seminarDates.indexOf(date.getTime()) > -1 ? true : false,
              classes: seminarDates.indexOf(date.getTime()) > -1 ? 'seminar-day' : ''
            };
          }
        });
        ApiService.get('api/staticpages/events?region=' + this.$route.query.region, {withCredentials: false}).then(res => {
          let courses = res.data.courses
          if(courses.length) {
            courses.forEach((course, key) => {
              courses[key].events_count = 0;
              this.seminars.forEach((event) => {
                if(event.course.id === course.id) {
                  courses[key].events_count++;
                  this.showFilters = true
                }
              })
            })
            this.courses = courses
          }
        })
      })
    })
  },
  methods: {
    enroll(eventId) {
      this.loading = true
      ApiService.post('ow/eventparticipants', {
        user: TokenService.getExportUserId(),
        event: eventId,
        order_id: this.$route.query.order_id
      }, false).then(res => {
        setTimeout(function () {
          window.location.href = res.data.link
          this.loading = false
        }, 3000)
      })
    }
  },
  watch: {
    filterCourses(val) {
      console.log(val)
    }
  },
  computed: {
    chunkSeminars() {
      if(this.seminars) {
        return _.chunk(Object.values(this.seminars), 5);
      }
      return null;
    }
  }
}
</script>

<style lang="scss">
  .seminar-day {
    background: #F2F5FE!important;
    box-shadow: 0 0 0 2px #bbb inset!important;
    color: #000000!important;
    &.active {
      box-shadow: 0 0 0 2px #6177ff inset!important;
    }
  }
  .no-seminar {
    margin: 140px 0 0;
    text-align: center;
  }
</style>