<template>
  <div class="content">
  <template v-if="course && participant">
      <Breadcrumbs :title="course.title"/>
      <div class="row mb-3 mb-xl-6">
        <div class="col-12 col-xl">
          <h2 class="content-medium">{{ course.title }}</h2>
        </div>
        <div class="col-auto mt-1 mt-xl-0">
          <a href="https://lk.exportcenter.ru/ru/educational-services" class="btn btn-primary btn_title-next">Образовательные сервисы</a>
        </div>
      </div>
      <Tabs :link="participant.link"/>
      <div class="seminar-about my-3">
        <div class="content_20 mb-1">Об этом {{ course.id === 54 ? 'пособии' : 'курсе' }}</div>
        <span v-if="course.full_desc" v-html="course.full_desc"></span>
        <span v-else v-html="course.purpose"></span>
      </div>
      <div class="row">
        <div class="col-xl-8 col-lg-9 col-md-11">
          <div class="seminar-materials">
            <div class="content_20 mb-2">Материалы {{ course.id === 54 ? 'пособия' : 'курса' }}</div>
            <div v-for="(module, key) in course.modules" class="collapse-card" :class="key > 0 ? 'mt-3' : ''" :key="key">
              <div class="collapse-card__header">
                <div class="row">
                  <div class="col">
                    <div class="collapse-card__title" v-b-toggle="'collapseExample' + key" data-toggle="collapse" href="#collapseExample" role="button"
                         aria-expanded="false" aria-controls="collapseExample">Модуль №{{key+1}} - {{ module.title.toLowerCase().capitalize() }}
                    </div>
                  </div>
                </div>
              </div>
              <b-collapse :visible="!key" :id="'collapseExample' + key">
                <div class="collapse-card__body">
                  <template v-for="(fragment, key) in module.video_fragments">
                    <a data-fancybox :data-thumb="getSnapshot(fragment)" :href="getQuality(fragment.formats)" :key="key" class="row-table-doc">
                      <div class="row-table-doc__col row-table-doc__type">
                        <img src="/img/icon-type-video.svg" />
                      </div>
                      <div class="row-table-doc__col row-table-doc__title">
                        <div class="row-table-doc__title-text">{{key + 1}}. {{fragment.title}}</div>
                        <div class="row-table-doc__title-type">Видеолекция</div>
                      </div>
                      <div class="row-table-doc__col row-table-doc__duration">
                        <span>{{ format(fragment.duration) }}</span>
                      </div>
                      <div class="row-table-doc__col row-table-doc__link">
                        <button class="btn btn_round-next"></button>
                      </div>
                    </a>
                  </template>
                  <template v-if="attachments">
                    <div v-for="(attach) in attachments" :key="attach.id" class="collapse-card__body">
                      <div class="content_22 content_medium mb-2">Методическое пособие</div>
                      <div class="file-info">
                        <div class="file-info__icon"><img src="/img/icon-pdf.svg" /></div>
                        <div class="file-info__content">
                          <div class="file-info__name content_500"><a target="_blank" :href="attach.file">{{ attach.name }}</a></div>
                          <div class="file-info__details content_12">{{attach.ext}}, {{attach.size}} МБ</div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </b-collapse>
            </div>
            <div v-for="(tutorial, key) in course.tutorials" class="collapse-card" :class="key > 0 ? 'mt-3' : ''" :key="key">
              <div class="collapse-card__header">
                <div class="row">
                  <div class="col">
                    <div class="collapse-card__title" v-b-toggle="'collapseExample' + key" data-toggle="collapse" href="#collapseExample" role="button"
                         aria-expanded="false" aria-controls="collapseExample">{{ tutorial.title }}
                    </div>
                  </div>
                </div>
              </div>
              <b-collapse :visible="!key" :id="'collapseExample' + key">
                <div class="collapse-card__body">
                  <div class="collapse-card__body">
                    <div class="content_22 content_medium mb-2">{{ tutorial.title }}</div>
                    <div class="file-info">
                      <div class="file-info__icon"><img src="/img/icon-pdf.svg" /></div>
                      <div class="file-info__content">
                        <div class="file-info__name content_500"><a target="_blank" :href="tutorial.file.file">{{ tutorial.file.name }}</a></div>
                        <div class="file-info__details content_12">{{tutorial.file.ext}}, {{tutorial.file.size}} МБ</div>
                      </div>
                    </div>
                  </div>
                  <div class="collapse-card__footer">
                    <div class="row justify-content-start">
                      <div class="col">
                        <a :href="tutorial.file.file" target="_blank" class="btn btn-primary">Скачать</a>
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
        <div class="col-xl-8 col-lg-9 col-md-11">
          <test v-if="participant.test" :test-id="participant.test.id" />
        </div>
      </div>
      <div class="row" v-if="testComplete">
        <div class="col-xl-8 col-lg-9 col-md-11">
          <div class="row justify-content-center" style="margin-top: 20px;">
            <button class="btn btn-primary" @click="sendLink()">
              <b-spinner v-if="startLoadingSend" />
              <span :class="{opacity: startLoadingSend}">
                Перейти к отзыву
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="content_20 my-3">Вы можете выбрать другой курс</div>
      <div class="carousel-wrapper" v-if="courses">
        <carousel :items="4" :loop="true" :nav="true" class="carousel-courses">
          <template slot="prev"><span class="prev"></span></template>
          <div class="item" v-for="(course, key) in courses" :key="key">
            <div class="carousel-courses__item">
              <div class="carousel-courses__item-img" v-if="course.preview" :style="{backgroundImage: 'url(https:' + course.preview['500x'] + ')'}"></div>
              <div class="carousel-courses__item-img" v-else :style="{backgroundImage: 'url(/img/course-bg.png)'}"></div>
              <div class="carousel-courses__item-type">Дистанционные курсы</div>
              <div class="carousel-courses__item-title">{{course.title}}</div>
              <div class="carousel-courses__item-link">
                <a :href="'/courses/' + course.id">Перейти к обучению</a>
              </div>
            </div>
          </div>
          <template slot="next"><span class="next"></span></template>
        </carousel>
      </div>
    </template>
  <pulse-loader style="position: relative; top: 50%; left: 50%;" color="#6177FF" v-else />
  <div v-if="showNotify" class="spinner-text">
      Подождите, пожалуйста, мы создаем для Вас заявку на дистанционный курс. Это займет не более 1 минуты
  </div>
  </div>
</template>

<script>
import Tabs from "../components/Tabs";
import Breadcrumbs from "../components/Breadcrumbs";
import carousel from 'vue-owl-carousel'
import ApiService from "../services/api.service";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import _ from 'lodash'
import $ from 'jquery';
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css'
import {TokenService} from "../services/token.service";
import Test from "../components/Test";
import {eventBus} from "../main";
window.jQuery = $

export default {
  name: "Online",
  components: {Test, Breadcrumbs, Tabs, carousel, PulseLoader},
  data() {
    return {
      participant: null,
      courses: null,
      attachments: [],
      testComplete: false,
      showNotify: false,
      course: null,
      program: 3,
      hasTest: window.location.hash === '#test',
      startLoadingSend: false,
      defaultPoster: 'https//exportedu.gcdn.co/video_fragments/34/jpg/5a5896b24e93c_720p.jpg'
    }
  },
  methods: {
    getSnapshot(fragment) {
      if(fragment.snapshot_about && fragment.snapshot_about['1280x720']) {
        return fragment.snapshot_about['1280x720'];
      }
      if(fragment.snapshot_about && fragment.snapshot_about['500x']) {
        return fragment.snapshot_about['500x'];
      }
      return this.defaultPoster;
    },
    getQuality(formats) {
      let q720 = null;
      formats.forEach(format => {
        if(format.resolution === '720p' && format.format === 'mp4') {
          q720 = format.file;
        }
      })
      return q720;
    },
    format(time) {
      // Hours, minutes and seconds
      const hrs = ~~(time / 3600);
      const mins = ~~((time % 3600) / 60);
      const secs = ~~time % 60;

      // Output like "1:01" or "4:03:59" or "123:03:59"
      let ret = "";
      if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
      }
      ret += "" + mins + ":" + (secs < 10 ? "0" : "");
      ret += "" + secs;
      return ret;
    },
    sendLink() {
      window.location.href = this.participant.link + '?feedback'
    },
    sendOrder() {
      this.startLoadingSend = true
      if(this.participant.test && this.participant.test.id) {
        ApiService.post('/ow/courseparticipants/' + this.participant.test.id + '/certificates', {
          user: TokenService.getExportUserId()
        }, false).then(() => {
          setTimeout(() => {
            this.startLoadingSend = false
          }, 10000)
        }).catch(() => {
          this.startLoadingSend = false
        })
      }
    },
  },
  mounted() {
    eventBus.$on('test:solved', () => {
      this.testComplete = true
    })
    eventBus.$on('test:finish', () => {
      this.sendOrder()
    })
    String.prototype.capitalize = function() {
      return this.charAt(0).toUpperCase() + this.slice(1);
    }

    eventBus.$on('auth', () => {
      ApiService.get('api/courses?limit=15&program=' + this.program, {withCredentials: false}).then(res => {
        this.courses = res.data.courses
        this.courses = _.sortBy(this.courses, ['is_new']);
        const courseId = this.$route.params.id ? this.$route.params.id : this.courses[0].id;
        this.courses = _.filter(this.courses, (item) => {
          return item.id != courseId
        });
        ApiService.get('api/courses/' + courseId + '?program=' + this.program, {withCredentials: false}).then(res => {
          this.course = res.data
          ApiService.get('/api/courses/' + courseId + '/attaches' + '?access_token=' + TokenService.getExportUser(),
              {withCredentials: false}
          ).then(res => {
            if(res.data && res.data.attachments) {
              this.attachments = res.data.attachments;
            }
          })
          ApiService.get('ow/courseparticipants/' + this.$route.params.id, {
            params: {
              course: courseId,
              user: TokenService.getExportUserId()
            },
            withCredentials: false
          }).then(res => {
            this.participant = res.data;
          }).catch(err => {
            if(err.response.status === 403) {
              this.showNotify = true
              ApiService.post('ow/courseparticipants', {
                course: courseId,
                user: TokenService.getExportUserId(),
              }, false).then(() => {
                this.showNotify = false
                window.location.reload()
              })
            }
          })
          require('@fancyapps/fancybox')
          $('[data-fancybox]').fancybox({
            video: {
              tpl:
                  '<video class="fancybox-video" controls controlsList="nodownload" poster="{{poster}}">' +
                  '<source src="{{src}}" type="{{format}}" />' +
                  "</video>",
              format: "", // custom video format
              autoStart: true
            },
          });
        })
      })
    })
  },
}
</script>

<style lang="scss">
.content {
  position: relative;
}
.spinner-text {
  text-align: center;
  position: absolute;
  top: 40%;
  width: 100%;
  left: 0;
}
</style>